import React, {useState} from "react";
import Section01 from "../section/section_01";
import {MotionAnimate} from "react-motion-animate";
import SectionAbout from "../section/section_about";
import SectionAuthor from "../section/section_author";
import axios from "axios";

import BOOK from '../data/book';

function PageNoteBook(props) {

    const [email, setEmail] = useState('');

    return (
        <div id={'slogan21'} className="page-book">
            <div className="sections">

                <MotionAnimate
                    animation='fadeInUp'
                    reset={true}
                    distance={200}
                    delay={1}
                    speed={1}>
                    {/*<MotionAnimate animation='scrollOpacity'>*/}
                    <div className="vh-100 container p-5">

                        <div id={'section_gift'} className="mt-5">
                            <h1 className={'display-2'}>
                               NEW Блокнот
                            </h1>
                            <small className="text-secondary mt-5 mb-5">
                                v2.0 (2024)
                            </small>

                            <br/>
                            <br/>

                            <div>
                                <div id={'gift'}
                                     className="col-md-12 d-flex row justify-content-between align-items-start bg-warning rounded-5 shadow-lg p-5">

                                    <div className={'col-md-8 p-md-5 p-0'}>
                                        <MotionAnimate
                                            animation='fadeInUp'
                                            reset={true}
                                            distance={200}
                                            delay={1}
                                            speed={0.75}>
                                            <h2 className={'custom-heading text-white w-auto'}>
                                                <b>{BOOK.giftTitle}</b>
                                            </h2>

                                        </MotionAnimate>


                                        <MotionAnimate
                                            animation='fadeInUp'
                                            reset={true}
                                            distance={200}
                                            delay={1}
                                            speed={0.8}>
                                            <p className={'p-md-2 p-0 display- text-white'}>
                                                {BOOK.giftDescription}
                                            </p>
                                        </MotionAnimate>

                                        <div className="row mt-5">
                                            <div className="col-md-8">
                                                <MotionAnimate
                                                    animation='fadeInUp'
                                                    reset={true}
                                                    distance={200}
                                                    delay={2}
                                                    speed={1}>
                                                    <div className="row">
                                                        <a href={'https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link'}
                                                           className="btn btn-outline-light shadow-lg text-white p-2 pt-3 pb-3 col-md-8 col-12 rounded-5">
                                            <span className="lead display-">
                                                🎁   Отримати
                                            </span>
                                                        </a>

                                                        <a href="/NOTEBOOK_template.pdf"  className="btn text-white p-2 pt-3 pb-3 col-md-4 col-12 rounded-5">
                                                            <small>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                                     fill="currentColor" className="bi bi-download"
                                                                     viewBox="0 0 16 16">
                                                                    <path
                                                                        d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                                    <path
                                                                        d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                                                </svg> шаблон
                                                            </small>
                                                        </a>
                                                    </div>

                                                </MotionAnimate>
                                            </div>



                                        </div>
                                    </div>


                                    <div className={'col-md-4 d-none d-md-block text-center'}>
                                        <MotionAnimate
                                            animation='fadeInUp'
                                            reset={true}
                                            distance={200}
                                            delay={2}
                                            speed={1.1}>
                                            <img
                                                src={'https://static.vecteezy.com/system/resources/previews/009/376/176/original/3d-black-book-illustration-png.png'}
                                                // src="https://ouch-cdn2.icons8.com/08wJb4_6UXEChpGVR0Y_FdjXpc5HeOdIz2wD8JuExSo/rs:fit:339:456/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvNzg0/L2FiZDE5ZjE2LTkx/OWQtNDU4MC04ODhi/LWM3N2E1OTBlYWI5/ZC5wbmc.png"
                                                height={'350'} alt="photo"
                                                className={'img-fluid1 rounded'}/>
                                        </MotionAnimate>
                                    </div>

                                </div>
                                <br/>
                                <a href="/NOTEBOOK_template_v1.pdf">
                                    <small>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                             fill="currentColor" className="bi bi-download"
                                             viewBox="0 0 16 16">
                                            <path
                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                            <path
                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                        </svg> Блокнот продуктивності v1.0 (2023)
                                    </small>
                                </a>
                            </div>

                        </div>

                    </div>
                </MotionAnimate>

                    <div
                        className="p-5 container bg-transparent vh-100 text-center pt-5 d-flex align-items-center justify-content-center">

                        <div className={'col-md-8 col-12'}>
                            <h2 className={'display-md-1 display-5 text-secondary'}>{BOOK.subTitle}</h2>
                            <a href="https://instagram.com/yariklukyanchuk" className={'display-5'} target={'_blank'}>@yariklukyanchuk</a>
                        </div>

                    </div>


            </div>
        </div>
    );
}

export default PageNoteBook;
