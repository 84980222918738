import React from "react";
import HeaderComponent from "../components/header";
import {ParallaxBanner} from "react-scroll-parallax";
import {MouseParallax} from "react-just-parallax";
import './book.css';
import {MotionAnimate} from "react-motion-animate";

function Section01(props) {
    return (
        // <section className={'bg-black text-white h-100'} >
        <section id={'slogan2'} className={'bg-black1 text-white h-1001'}>


            <div className="container min-vh-100 text-center row m-auto align-items-center justify-content-center">
                <div className="col-md-4 col-12 text-end">
                    <small className="title-color1">
                        Книга
                    </small> <br/>
                    <h1 className={'custom-heading title-color1'}> 1% Змін.</h1>
                </div>
                <div className="col-md-4 col-12 text-center">
                    <a
                        className="book-container"
                        href="#slogan"
                        // target="_blank"
                        rel="noreferrer noopener"
                    >
                        <div className="book">
                            <img
                                alt="book"
                                src={'/image/cover/book_cover.png'}
                                // src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/yellow-business-leadership-book-cover-design-template-dce2f5568638ad4643ccb9e725e5d6ff_screen.jpg"
                                // src="https://m.media-amazon.com/images/M/MV5BNjUxYTkxYzgtYzU5OC00NDVmLWExYTAtYmY0NDBiZWRhY2E0XkEyXkFqcGdeQXVyNzEzMzA1MTQ@._V1_UY1200_CR645,0,630,1200_AL_.jpg"
                            />
                        </div>
                    </a>
                </div>
                <div className="col-md-4 col-12 text-start">
                    <h2 className={'custom-heading title-color'}>Час зроби це зараз!</h2>

                </div>

            </div>


        </section>
    );
}

export default Section01;