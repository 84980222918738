import React from "react";

function HeaderComponent() {
    return (
        <header className={''}>
            {
                window.location.pathname === '/' && <div className={'bg-white1 p-2 container d-flex justify-content-between align-items-center fixed-top'}>

                    <div>
                        <small className={'text-uppercase1 text-white lead'}><img src="https://lukyanchuk.com/assets/logo/logo.png" alt="logo" style={{width: 38}}/> </small>
                    </div>

                    <div>
                        <span className={'p-1'}>
                        <a href="/books" className={'text-dark'}>Книги</a>
                    </span>
                    {/*    <span className={'p-1'}>*/}
                    {/*    <img src="https://www.svgrepo.com//show/508666/flag-ua.svg" alt="flag-ua.svg" width={'14'}/>*/}
                    {/*</span>*/}
                    </div>
                </div>
            }
        </header>
    );
}

export default HeaderComponent;
