import React from "react";
import * as Icon from "react-bootstrap-icons";
import BOOK from "../data/book";

function SectionAuthor(props) {
    return (
        <div id={'bg'} className={'text-white p-md-5 p-1'}>
            <div className="min-vh-100 container p-5 align-items-center d-flex">
                <div id={'author'} className="">
                    <h4 className={'display-5'}>Про автора</h4>

                    <br/>

                    <div>
                        <div className="row d-flex justify-content-between align-items-start">

                            <div className={'col-md-4 col-12 align-items-center d-flex justify-content-between row'}>
                                <div
                                    className="col-md-3 col-3 d-flex flex-column text-center justify-content-between align-content-center pb-3 pt-4">
                                    <div className="p-2 rounded">
                                        <a href="https://instagram.com/yariklukyanchuk/" target={'_blank'}>
                                            <Icon.Instagram
                                                color="white"
                                                size={22}
                                            />
                                        </a>
                                    </div>
                                    <div className="p-2 rounded">
                                        <a href="https://facebook.com/yarik.lukyanchuk/" target={'_blank'}>
                                            <Icon.Facebook
                                                color="white"
                                                size={22}
                                            />
                                        </a>
                                    </div>
                                    <div className="p-2  rounded">
                                        <a href="https://twitter.com/yariklukyanchuk" target={'_blank'}>
                                            <Icon.Twitter
                                                color="white"
                                                size={22}
                                            />
                                        </a>
                                    </div>
                                    <div className="p-2  rounded">
                                        <a href="https://youtube.com/@YarikLukyanchuk" target={'_blank'}>
                                            <Icon.Youtube
                                                color="white"
                                                size={22}
                                            />
                                        </a>
                                    </div>
                                    <div className="p-2  rounded">
                                        <a href="https://linkedin.com/in/yaroslav-lukyanchuk-a028481ab/"
                                           target={'_blank'}>
                                            <Icon.Linkedin
                                                color="white"
                                                size={22}
                                            />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-9 col-9 position-relative">
                                    <img src="https://v3.lukyanchuk.com/storage/app/public/files/1/img/yaroslav.jpeg"
                                         alt="photo" className={'img-fluid rounded-5 '}/>
                                    <div id={'slogan2'}
                                        className="d-none d-md-block rounded-5 p-2 pt-3 bg-white shadow-lg opacity-_75 top-100 position-absolute start-100 translate-middle w-100">
                                        <h2 className="text-dark lead_ display-61 text-center">
                                            {BOOK.authorSlogan}
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className={'col-md-8 p-md-5 p-1 col-12'}>

                                <p className={'lead'}>
                                    {BOOK.authorDescription}
                                </p>

                                {/*<p className={'mt-5'}>*/}
                                {/*    <a href="https://lukyanchuk.com" className={'bg-white p-1'}>https://lukyanchuk.com</a>*/}
                                {/*</p>*/}

                                <small className="text-secondary lead1">
                                    <u>
                                        <a href="https://lukyanchuk.com"  className={'text-white'}>© 2023 Лук'янчук Ярослав</a>
                                    </u>
                                </small>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SectionAuthor;
