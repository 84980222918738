import React from "react";
import {useParallax} from "react-scroll-parallax";
import {MouseParallax, ScrollParallax} from "react-just-parallax";
import {MotionAnimate} from "react-motion-animate";
import BOOK from "../data/book";

function SectionAbout(props) {

    return (
        <section>
            <MotionAnimate reset={true}>
                <div className="container min-vh-100 p-5 pt-0">

                    <div className="row">
                        <div className="col-md-4">
                            <MotionAnimate
                                animation='fadeInUp'
                                reset={true}
                                distance={200}
                                delay={1}
                                speed={1}>
                                <div className="col-md-12">
                                    <div className="borderrounded-5shadow p-2 pt-5 mt-3"  style={{height: '75vh'}}>

                                        <h2>Книга</h2>

                                        <div className={'mt-3'}>
                                            <small className="text-secondary">
                                                опис
                                            </small>

                                            <p className={'mt-3'}>
                                                {BOOK.description}
                                            </p>
                                        </div>

                                        <div className={'mt-3'}>
                                            <small className="text-secondary">автор </small> <br/>
                                            <b className={'lead'}>
                                                <a href="#author">{BOOK.author}</a>
                                            </b>
                                        </div>


                                        {/*<div className={'mt-3'}>*/}
                                        {/*    <small className="text-secondary">вартість </small> <br/>*/}
                                        {/*    <b className={'lead'}>*/}
                                        {/*        390 грн*/}
                                        {/*    </b>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </MotionAnimate>
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="border rounded-5 shadow p-5 mt-3">*/}
                            {/*        <div>*/}
                            {/*            <small className="text-secondary">автор </small> <br/>*/}
                            {/*            <b className={'lead'}>*/}
                            {/*                <a href="#author">Лук'янчук Ярослав</a>*/}
                            {/*            </b>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <MotionAnimate
                                animation='fadeInUp'
                                reset={true}
                                distance={200}
                                delay={1}
                                speed={1}>
                                <div className="col-md-12">
                                    <div className="border rounded-5 shadow p-5 mt-3 bg-dark">
                                        <div className={'text-center'}>
                                            <b className={'lead display-6'}>
                                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link">
                                                    <small className="text-white">
                                                        Замовить
                                                    </small>
                                                </a>
                                            </b>
                                        </div>
                                    </div>
                                </div>
                            </MotionAnimate>
                            {/*<div className="col-md-12">*/}
                            {/*    <div className="p-5">*/}
                            {/*        <div>*/}
                            {/*            <small className="text-secondary">автор </small> <br/>*/}
                            {/*            <b className={'lead'}>*/}
                            {/*                <a href="#author">{BOOK.author}</a>*/}
                            {/*            </b>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="border1 rounded-5 shadow1 p-5 mt-3">
                                        <h4 className={'display-6'}>1% Змін.
                                            <br/><h5 className={'lead text-secondary'}>Час зроби це зараз!</h5>
                                        </h4>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12">
                                <div className="border1 rounded-5 shadow1 p-5 pt-0 pb-0 mt-31 text-center">
                                    <MotionAnimate
                                        animation='fadeInUp'
                                        reset={true}
                                        distance={200}
                                        delay={1}
                                        speed={1}>
                                        {/*<img*/}
                                        {/*    src={'https://cdn3d.iconscout.com/3d/premium/thumb/book-4909705-4089425.png?f=webp'}*/}
                                        {/*    // src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/yellow-business-leadership-book-cover-design-template-dce2f5568638ad4643ccb9e725e5d6ff_screen.jpg"*/}
                                        {/*    alt="photo" className={'img-fluid1'} height={300}/>*/}
                                        <img
                                            // src={'/image/cover/book_cover.png'}
                                            src={'https://static.vecteezy.com/system/resources/previews/009/376/176/original/3d-black-book-illustration-png.png'}
                                            // src="https://media.istockphoto.com/id/1349057211/photo/flying-yellow-books-on-a-isolated-white-background.jpg?s=612x612&w=0&k=20&c=Axg6Ohp0K223AguF3loDdEf8UWtOMOn5TGnLE0ulXXQ="
                                            alt="book" className={'img-fluid'} />
                                    </MotionAnimate>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex flex-wrap border1 rounded-5shadow p-4 mt-3">

                                        <div className={'border-end p-3'}>
                                            <small className="text-secondary">рік видання </small> <br/>
                                           <small><b className={'lead1'}>{BOOK.yearPublish}</b></small>
                                        </div>

                                        <div className={'border-end p-3'}>
                                            <small className="text-secondary">палітурка </small> <br/>
                                            <small><b className={'lead1'}>{BOOK.palityrka}</b></small>
                                        </div>

                                        <div className={'border-end p-3'}>
                                            <small className="text-secondary">формат </small> <br/>
                                            <small><b className={'lead1'}> {BOOK.paperFormat} </b></small>
                                        </div>

                                        <div className={'border-end p-3'}>
                                            <small className="text-secondary">сторінок </small> <br/>
                                            <small className={'lead1'}><b>{BOOK.countPages}</b></small>
                                        </div>

                                        {/*<div className={'border-end p-3'}>*/}
                                        {/*    <small className="text-secondary">видавництво </small> <br/>*/}
                                        {/*    <b className={'lead'}>-НАЗВА-</b>*/}
                                        {/*</div>*/}

                                        <div className={'p-3'}>
                                            <small className="text-secondary">мова </small> <br/>
                                            <small><b className={'lead1'}>{BOOK.bookLanguage}</b></small>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">

                            <MotionAnimate
                                animation='fadeInUp'
                                reset={true}
                                distance={200}
                                delay={1}
                                speed={1}>
                                <div className="col-md-12">
                                    <div id={'gift'}
                                         className="border rounded-5 shadow p-md-5 p-1 mt-3 align-items-center justify-content-center d-flex"
                                         style={{height: '92vh'}}>
                                        <MotionAnimate
                                            animation='fadeInUp'
                                            reset={true}
                                            distance={200}
                                            delay={1}
                                            speed={1.3}>
                                            <a href="#section_gift">
                                                <div className={'text-center'}>
                                                    <h4 className={'display-11'} style={{
                                                        fontSize: "200px"
                                                    }}>🎁</h4>

                                                    {/*<b className={'text-white'}>Get GIFT</b>*/}

                                                </div>
                                            </a>
                                        </MotionAnimate>

                                    </div>
                                </div>
                            </MotionAnimate>
                        </div>
                    </div>

                </div>

                <div id={'bg1'} className={'bg-black text-white p-md-5 p-1 mt-5'}>
                    <div className="vh-100 container-fluid d-flex align-items-center justify-content-center">

                <span className="display-2">
                    Не подобається зміню, не змінюєш не ний!
                </span>

                    </div>
                </div>

            </MotionAnimate>


            <MotionAnimate
                animation='fadeInUp'
                reset={true}
                distance={200}
                delay={1}
                speed={1}>
                {/*<MotionAnimate animation='scrollOpacity'>*/}
                <div className="min-vh-100 container p-5">

                    <div id={'section_gift'} className="mt-5">
                        <h4 className={'display-2'}>🎁 Подарунок</h4>

                        <br/>

                        <div>
                            <div id={'gift'}
                                 className="col-md-12 d-flex row justify-content-between align-items-start bg-warning rounded-5 shadow-lg p-5">

                                <div className={'col-md-8 p-md-5 p-0'}>
                                    <MotionAnimate
                                        animation='fadeInUp'
                                        reset={true}
                                        distance={200}
                                        delay={1}
                                        speed={0.75}>
                                            <h2 className={'custom-heading text-white'}>
                                                <b>{BOOK.giftTitle}</b>
                                            </h2>
                                    </MotionAnimate>


                                    <MotionAnimate
                                        animation='fadeInUp'
                                        reset={true}
                                        distance={200}
                                        delay={1}
                                        speed={0.8}>
                                    <p className={'p-md-2 p-0 display- text-white'}>
                                        {BOOK.giftDescription}
                                    </p>
                                    </MotionAnimate>

                                    <div className="row mt-5">
                                        <div className="col-md-8">
                                            <MotionAnimate
                                                animation='fadeInUp'
                                                reset={true}
                                                distance={200}
                                                delay={2}
                                                speed={1}>
                                            <div className="row">
                                                <a href={'https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link'}
                                                   className="btn btn-outline-light shadow-lg text-white p-2 pt-3 pb-3 col-md-8 col-12 rounded-5">
                                            <span className="lead display-">
                                                🎁   Отримати
                                            </span>
                                                </a>

                                                <a href="/NOTEBOOK_template.pdf"  className="btn text-white p-2 pt-3 pb-3 col-md-4 col-12 rounded-5">
                                                    <small>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                             fill="currentColor" className="bi bi-download"
                                                             viewBox="0 0 16 16">
                                                            <path
                                                                d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                                                            <path
                                                                d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                                                        </svg> шаблон
                                                    </small>
                                                </a>
                                            </div>
                                                
                                            </MotionAnimate>
                                        </div>

                                    </div>
                                </div>


                                <div className={'col-md-4 d-none d-md-block text-center'}>
                                    <MotionAnimate
                                        animation='fadeInUp'
                                        reset={true}
                                        distance={200}
                                        delay={2}
                                        speed={1.1}>
                                    <img
                                        src={'https://static.vecteezy.com/system/resources/previews/009/376/176/original/3d-black-book-illustration-png.png'}
                                        // src="https://ouch-cdn2.icons8.com/08wJb4_6UXEChpGVR0Y_FdjXpc5HeOdIz2wD8JuExSo/rs:fit:339:456/czM6Ly9pY29uczgu/b3VjaC1wcm9kLmFz/c2V0cy9wbmcvNzg0/L2FiZDE5ZjE2LTkx/OWQtNDU4MC04ODhi/LWM3N2E1OTBlYWI5/ZC5wbmc.png"
                                        height={'350'} alt="photo"
                                        className={'img-fluid1 rounded'}/>
                                    </MotionAnimate>
                                </div>

                            </div>
                        </div>

                    </div>

                </div>
            </MotionAnimate>
        </section>
    );

    // const parallax = useParallax < HTMLDivElement > ({
    //     easing: 'easeOutQuad',
    //     translateX: [0, 100],
    // });
    // return <div ref={parallax.ref}/>;
}

export default SectionAbout;
