
import './App.css';
import MainPage from "./pages/main";
import PageLoyauts from "./layouts/page";
import {Routes, Route, Link, Navigate} from "react-router-dom";
import Page404 from "./pages/404";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ParallaxProvider} from "react-scroll-parallax";
import PageBook from "./pages/book";
import BooksPage from "./pages/books";
import PageNoteBook from "./pages/notebook";

function App() {
  return (
    <>
        {/*routes*/}
        <ParallaxProvider>
            <Routes>
                <Route index exact path={'/'} element={<PageLoyauts page={  <MainPage />} />}/>

                <Route exact path={'/books'} element={<PageLoyauts page={  <BooksPage />} />}/>

                <Route exact path={'/book/:name'} element={<PageLoyauts page={  <PageBook />} />}/>

                <Route exact path={'/productivity-notebook'} element={<PageLoyauts page={  <PageNoteBook />} />}/>

                <Route path="*" element={<Page404/>}/>
            </Routes>
        </ParallaxProvider>
    </>
  );
}

export default App;
