import React, {useEffect, useRef, useState} from "react";

function BooksPage() {

    const ref = useRef()

    return (
       <div className={'container'}>

           <div className={'min-vh-100 d-flex justify-content-center align-items-center'}>

               <main className={'books w-100 pt-5'}>
                   <h2 className={'text-dark'}>Книги</h2>
                   <br/>
                   <div className="d-flex flex-wrap">

                       <div className="col-md-3 col-12">
                           <a href="/book/one-percent-changes-do-it-now/">
                           <div className="bg-white p-2">
                               <img
                                   alt="book"
                                   className={'img-fluid shadow rounded'}
                                   src={'/image/cover/book_cover.png'}
                                   // src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/yellow-business-leadership-book-cover-design-template-dce2f5568638ad4643ccb9e725e5d6ff_screen.jpg"
                                   // src="https://m.media-amazon.com/images/M/MV5BNjUxYTkxYzgtYzU5OC00NDVmLWExYTAtYmY0NDBiZWRhY2E0XkEyXkFqcGdeQXVyNzEzMzA1MTQ@._V1_UY1200_CR645,0,630,1200_AL_.jpg"
                               />



                              <div className="d-flex flex-wrap p-1 mt-3">
                                  <div className={'pb-3'}>
                                      <h4 className={'m-0 p-0'}>1% Змін.</h4>
                                      <small className="text-secondary">
                                          Час зроби це зараз!
                                      </small>
                                  </div>

                                  <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link" className={'btn btn-dark text-white col-12 pt-3 pb-3'}>Замовить</a>
                              </div>

                           </div>
                           </a>
                       </div>

                       <div className="col-md-3 col-12">
                           <a href="/productivity-notebook/">
                               <div className="bg-white p-2">
                                   <img
                                       alt="book"
                                       className={'img-fluid shadow rounded'}
                                       src={'/image/cover/v1-NOTEBOOK.png'}
                                   />

                                   <div className="d-flex flex-wrap p-1 mt-3">
                                       <div className={'pb-3'}>
                                           <h4 className={'m-0 p-0'}>Блокнот</h4>
                                           <small className="text-secondary">
                                               продуктивності
                                           </small>
                                       </div>

                                       <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5nJLkAQ1NBxCQc3JC4oYBjWcGLiCmqa_KU1swwCUy1XUx9w/viewform?usp=sf_link" className={'btn btn-dark text-white col-12 pt-3 pb-3'}>Замовить</a>
                                   </div>

                               </div>
                           </a>
                       </div>


                   </div>


               </main>
           </div>
       </div>
    );
}

export default BooksPage;
