import React, {useEffect, useState} from "react";
import Section01 from "../section/section_01";
import {MotionAnimate} from "react-motion-animate";
import SectionAbout from "../section/section_about";
import SectionAuthor from "../section/section_author";
import axios from "axios";
import { redirect } from "react-router-dom";
import BOOK from '../data/book';
import {useParams} from "react-router";

function PageBook(props) {

    const bookPath = 'one-percent-changes-do-it-now';

    const {name} = useParams();

    const [email, setEmail] = useState('');

    const subscribeForm = (e) => {
        e.preventDefault();

        try {

            if (email === '') {
                document.getElementById('emailInput').classList.add('border-danger');
            } else {
                document.getElementById('sub-form').remove();
                document.getElementById('txt-done').classList.remove('d-none');
            }

            // axios.post(
            //     'https://office.lukyanchuk.com/api/mail/subscribe', {},
            //     {
            //         header : {
            //             '': ''
            //         }
            //     }
            // ).then(response => {
            //
            //     if(response.status === 200){
            //
            //         //TODO: show Thank Message
            //     }else{
            //         //TODO: show Error Message
            //     }
            //
            // }).catch(e => {
            //     alert(e.message)
            // })

        } catch (e) {
            alert(e)
        }
    }

    useEffect(()=>{
        if(bookPath !== name){
            window.location.href = '/books'
        }
    }, [])

    return (
        <div id={'slogan21'} className="page-book">
            <div className="sections">

                {/*EXAMPLE FOR DESIGN: https://www.joinbandit.com/*/}

                {/*<Parallax pages={4} ref={ref}>*/}
                {/*    <ParallaxLayer offset={0} speed={2.5}>*/}
                {/*        <MouseParallax>*/}

                {/*        </MouseParallax>*/}
                {/*    </ParallaxLayer>*/}
                {/*   */}
                {/*</Parallax>*/}
                <Section01/>

                <MotionAnimate animation='scrollPosition' yPos={[100, -215]}>
                   <div id={'slogan'}>
                       <div
                            className={'container d-flex flex-wrap justify-content-center text-dark align-items-center p-5 mt-5'}>
                           {/*<div className={'col-md-6 col-12'}>*/}
                           {/*    <small className="text-secondary">*/}
                           {/*        📙 Книга*/}
                           {/*    </small>*/}
                           {/*    <br/>*/}
                           {/*    <span className={'display-5'}>*/}
                           {/*        {BOOK.title}*/}
                           {/*     </span>*/}
                           {/*</div>*/}
                           <div className={'col-md-12 col-12'}>
                               <small className="text-secondary">
                                   Slogan
                               </small>
                               <br/>
                               <span className={'text-white display-2'}>{BOOK.sloganShort}</span>
                           </div>
                       </div>
                   </div>
                </MotionAnimate>

                <SectionAbout/>

                <MotionAnimate
                    animation='scrollOpacity'
                    ease={[0.17, 0.67, 0.97, -0.48]}
                    scrollPositions={[0.2, 0.5, 0.6, 0.8]}>
                    <SectionAuthor/>
                </MotionAnimate>

                <MotionAnimate
                    animation='fadeInUp'
                    reset={true}
                    distance={200}
                    delay={1}
                    speed={1}>
                    <div
                        className="p-5 container bg-transparent min-vh-100 text-center pt-5 d-flex align-items-center justify-content-center">

                        <div className={'col-md-8 col-12'}>
                            <h2 className={'display-md-1 display-5 text-secondary'}>{BOOK.subTitle}</h2>
                            <a href="https://instagram.com/yariklukyanchuk" className={'display-5'} target={'_blank'}>@yariklukyanchuk</a>
                            {/*<h3 id={'txt-done'} className="d-none display-1 mt-5 text-success">Дякую!</h3>*/}
                            {/*<div id={'sub-form'}*/}

                            {/*    // onSubmit={(e)=>subscribeForm()} method={'POST'}*/}
                            {/*     className={'col-md-7 m-auto d-flex align-items-end justify-content-center mt-5 pt-5'}>*/}
                            {/*    <div className="col-md-8 p-1">*/}
                            {/*        <input id={'emailInput'} type="text" placeholder={'name@mail.com'}*/}
                            {/*               onChange={(e) => setEmail(e.target.value)}*/}
                            {/*               className={'form-control rounded-5 p-3'}/>*/}
                            {/*    </div>*/}
                            {/*    <div className="col-md-4 p-1">*/}
                            {/*        <input id={'gift'} onClick={subscribeForm} type="submit" value={'Join'}*/}
                            {/*               className={'btn btn-warning rounded-5 w-100 p-3'}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>

                    </div>

                </MotionAnimate>


            </div>
        </div>
    );
}

export default PageBook;
