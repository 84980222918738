import React from "react";

function Page404(props) {
    return (
        <div className="page-404">
            <div className="col-md-6 col-12 text-center m-auto pt-2">

                <h1>Помилка #404</h1>
                <p className="lead">
                    Сторінку не знайдено.
                </p>
                <a href="/" className="btn btn-dark text-white p-3 rounded-pill">
                    На головну
                </a>
            </div>
        </div>
    );
}

export default Page404;
