import React, {useEffect, useRef, useState} from "react";
import './css/main.css';
import Section01 from "../section/section_01";
import {MouseParallax} from "react-just-parallax";

function MainPage() {

    const ref = useRef()

    return (
        <div className={'vh-100 container mt-5 pt-5 pb-5 mb-5 d-flex justify-content-center align-items-center'}>
            <div>
                <div className="wave"></div>
                <div className="wave"></div>
                <div className="wave"></div>
            </div>

            <main className={'d-flex justify-content-center align-items-center'}>


                <MouseParallax>


                <div className="col-md-12 col-12 text-center">
                    <a
                        className="book-container"
                        href="/book/one-percent-changes-do-it-now"
                        rel="noreferrer noopener"
                    >
                        <div className="book">
                            <img
                                alt="book"
                                src={'/image/cover/book_cover.png'}
                                // src="https://d1csarkz8obe9u.cloudfront.net/posterpreviews/yellow-business-leadership-book-cover-design-template-dce2f5568638ad4643ccb9e725e5d6ff_screen.jpg"
                                // src="https://m.media-amazon.com/images/M/MV5BNjUxYTkxYzgtYzU5OC00NDVmLWExYTAtYmY0NDBiZWRhY2E0XkEyXkFqcGdeQXVyNzEzMzA1MTQ@._V1_UY1200_CR645,0,630,1200_AL_.jpg"
                            />
                        </div>
                    </a>
                </div>
                </MouseParallax>
                    <div className="ud_book_box w-100">
                        <div className="ud_book">
                            <div className="ud_ruckseite">
                                <div></div>
                            </div>
                            <h1 className={'mt-5'}>1% Змін.</h1>
                            <div className="ud_cover">
                                <div>
                                    <p>Час зроби це зараз!</p>
                                </div>
                            </div>
                        </div>
                    </div>




            </main>
        </div>
    );
}

export default MainPage;
