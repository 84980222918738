import React from "react";
import HeaderComponent from "../components/header";
import FooterComponent from "../components/footer";
import ScrollToTop from "react-scroll-to-top";

function PageLoyauts(props) {
    return (
        <div className={window.location.pathname === '/' ? 'wrapper bg1' : 'wrapper'}>
            <HeaderComponent />
            <main>
                {
                    props.page
                }
            </main>
            <FooterComponent />
            <ScrollToTop smooth />
        </div>
    );
}

export default PageLoyauts;
