import React from "react";
import SectionContact from "../section/section_contact";
import * as Icon from "react-bootstrap-icons";

function FooterComponent() {
    return (
        <div className={'footer-section pt-0'}>

            <footer className="footer-section bg-dark pb-4 pb-md-0">
                <div className="container row m-auto p-md-5 p-2 pb-4 pt-0">
                    <div className="col-12 col-md-6 text-md-start mt-md-0 mt-3 text-center order-2 order-md-1">
                        <h2
                        className="text-secondary"><img src="https://lukyanchuk.com/assets/logo/logo.png" alt="logo" style={{width: 32}}/>  Книги</h2>
                        <br/>
                        <small className={'text-white'}>Зроблено з ❤️ в 🇺🇦</small>
                        <br/>
                        <small className="mt-5 text-secondary">Авторське право © {new Date().getFullYear()}. <a
                            href="https://lukyanchuk.com" className="text-decoration-none text-secondary"
                            target="_blank"> Лук'янчук Я.В.</a></small>
                    </div>
                    <div className="col-12 col-md-6 text-md-end text-center order-1 order-md-2"><h2
                        className="text-secondary">Інформація</h2>
                        <div className="d-flex flex-column"><small><a className="text-decoration-none text-secondary"
                                                                      href="mailto:yarik@lukyanchuk.com">yarik@lukyanchuk.com</a>
                            <a href="https://t.me/YaroslavLukyanchuk" target="_blank"
                               className="text-decoration-none lead">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                     className="bi bi-telegram text-secondary m-1" viewBox="0 0 16 16">
                                    <path
                                        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
                                </svg>
                            </a></small>
                            <br/>
                            <small className="mt-2"><a href="https://lukyanchuk.com/information"
                                                                   className="text-decoration-none text-secondary"
                                                                   target="_blank">Умови використання & Політика конфіденційності</a></small>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    );
}

export default FooterComponent;
