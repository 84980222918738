class BookInformation {

    constructor() {
        this.init();
    }

    init(){

        this.title = '1% Змін. Час зроби це зараз!';
        this.titleShort = '1% Змін.';
        this.slogan = '1% Змін. Час зроби це зараз! Стань кращой версією себе.';
        this.sloganShort = 'Стань кращой версією себе!';
        this.description = 'Книга написана, для того щоб змінитись самому та допомогти комусь також стати краще, ' +
            'принані змінитись на 1% в кращу сторону. Сподіваюсь своїми прикладами та порадами, я дав вам приклад для натхнення та стимул до дій!';
        this.author = 'Лук\'янчук Ярослав';
        this.yearPublish = '2024';
        this.palityrka = 'Тверда';
        this.paperFormat = 'A5';
        this.countPages = '101';
        this.bookLanguage = 'Українська';

        this.giftTitle = 'Блокнот продуктивності';
        this.giftDescription = 'Блокнот підвищіть твою продуктивність, дасть тобі змогу сфокусуватись на дійсно потрібних для тебе речех та справах, відкинувши все зайве.';

        this.authorSlogan = 'Carpe diem';
        this.authorDescription = 'Привіт, мене звати Ярослав. Я написав цю книгу, для того щоб змінитись самому та допомогти комусь також стати краще, ' +
            'принані змінитись на 1% в кращу сторону. Сподіваюсь своїми прикладами та порадами, я дав вам приклад для натхнення та стимул до дій!';

        this.subTitle = 'Підпишись!';
    }
}

export default new BookInformation();
